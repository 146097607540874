import type { SubmitOrientationRequestRequest } from '$lib/api/generated/cta-services';

import { get, writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { browser } from '$app/environment';

export class OrientationStore {

  private static _instance: OrientationStore;
  private _orientationRequest: Writable<SubmitOrientationRequestRequest | null>;
  private _isLastStepSubmitted: Writable<boolean>;
  private _occupationChoosen: Writable<string | null>;

  constructor() {
    this._orientationRequest = writable(null);
    this._isLastStepSubmitted = writable(false);
    this._occupationChoosen = writable(null);
  }

  setOrientationRequest(orientationRequest: SubmitOrientationRequestRequest) {
    this._orientationRequest.set(orientationRequest);
    if (browser) {
      localStorage.setItem('orientation', JSON.stringify(orientationRequest));
    }
  }

  setIsLastStepSubmitted(isLastStepSubmitted: boolean) {
    this._isLastStepSubmitted.set(isLastStepSubmitted);
  }

  setOccupationChoosen(occupationChoosen: string) {
    this._occupationChoosen.set(occupationChoosen);
  }

  getOrientationRequest() { 
    if (get(this._orientationRequest)) {
      return get(this._orientationRequest);
    }
    if (browser) {
      const orientationRequest = localStorage.getItem('orientation');
      return JSON.parse(orientationRequest);
    }
  }

  getIsLastStepSubmitted() {
    return get(this._isLastStepSubmitted);
  }

  getOccupationChoosen() {
    if (get(this._occupationChoosen)) {
      return get(this._occupationChoosen);
    }
    if (browser) {
      const orientationRequest = localStorage.getItem('orientation');
      return JSON.parse(orientationRequest)?.orientation?.currentOccupation;
    }
  }

  clearOrientation() {
    this._orientationRequest.set(null);
    this._occupationChoosen.set(null);
    if (browser) {
      localStorage.removeItem('orientation');
    }
  
  }

  static getInstance() {
    if (!OrientationStore._instance) {
      OrientationStore._instance = new OrientationStore();
    }
    return OrientationStore._instance;
  }
}